const pageData = {
  menu: {
    isActive: null,
    list: {
      desktop: [
        { 
          link: '/vantagens-e-diferenciais',
          icon: '',
          label: 'Vantagens e Diferenciais',
          isExternal: false
        },
        { 
          link: '/quem-somos',
          icon: '',
          label: 'Quem Somos',
          isExternal: false
        },
        { 
          link: '/blog',
          icon: '',
          label: 'Blog',
          isExternal: false
        },
        { 
          link: '/institutional/ajuda',
          icon: '',
          label: 'Ajuda',
          isExternal: false
        }
      ],
      mobile: [
        { 
          link: '/',
          icon: 'home',
          label: 'Início',
          isExternal: false
        },
        { 
          link: '/vantagens-e-diferenciais',
          icon: 'benefits',
          label: 'Vantagens e Diferenciais',
          isExternal: false
        },
        { 
          link: '/quem-somos',
          icon: 'about',
          label: 'Quem Somos',
          isExternal: false
        },
        { 
          link: '/blog',
          icon: 'blog',
          label: 'Blog',
          isExternal: false
        },
        { 
          link: '/institutional/ajuda',
          icon: 'help',
          label: 'Ajuda',
          isExternal: false
        },
        { 
          link: '/institutional/emprestimo-pessoal',
          icon: 'follow',
          label: 'Empréstimo Pessoal',
          isExternal: true,
        }
      ]
    }
  }
}

export { pageData }